// @flow
import { put, fork, call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import contentRange from 'content-range'
import { ETL_ORCHESTRATE_ENDPOINT } from 'constants/endpoints'
import { errorModal, successModal } from '../actions/modal-actions'
import { createAlert } from '../actions/app-actions'

import {
  TASK_RETRY,
  TASK_UPDATE,
  TASK_DELETE,
  TASK_LOAD,
  ORCHESTRATE,
  TASK_CREATE,
  TASK_LOAD_SUCCEEDED,
  TASK_LOAD_FAILED,
  UPDATE_ETL_CHECKPOINT,
  TASK_DOAGAIN,
  ETL_STATE_LOAD,
  ETL_STATE_LOAD_SUCCEEDED,
  ETL_STATE_LOAD_FAILED,
  UPDATE_ETL_PARAM
} from '../actions/task-actions'

import {
  FETCH_ETL_STATS,
  fetchETLStatsSuccess,
  fetchETLStatsFailed,
  FETCH_ETL_STATS_TIMEFRAME,
  fetchETLStatsTimeframeFailed,
  fetchETLStatsTimeframeSuccess
} from '../actions/etl-stats-actions'

function* taskLoadFlow() {
  yield takeLatest(TASK_LOAD, loadTasks)
  yield takeLatest(FETCH_ETL_STATS_TIMEFRAME, fetchETLStatsTimeframe)
  yield takeLatest(FETCH_ETL_STATS, fetchETLStats)
}

function* taskUpdateFlow() {
  yield takeLatest(TASK_UPDATE, taskUpdate)
}

function* taskDeleteFlow() {
  yield takeLatest(TASK_DELETE, taskDelete)
}

function* taskRetryFlow() {
  yield takeLatest(TASK_RETRY, taskRetry)
}

function* taskDoAgainFlow() {
  yield takeLatest(TASK_DOAGAIN, taskDoAgain)
}

function* orchestrateFlow() {
  yield takeLatest(ORCHESTRATE, orchestrate)
}

function* taskCreateFlow() {
  yield takeLatest(TASK_CREATE, taskCreate)
}

function* updateEtlCheckpointFlow() {
  yield takeEvery(UPDATE_ETL_CHECKPOINT, updateEtlCheckpoint)
}

function* etlStateFlow() {
  yield takeLatest(ETL_STATE_LOAD, loadETLStates)
  yield takeLatest(UPDATE_ETL_PARAM, loadETLStates)
}

function* orchestrate(action) {
  try {
    const response = yield call(() =>
      axios.post(ETL_ORCHESTRATE_ENDPOINT, {
        type: action.taskType,
        metadata: {
          ...action.metadata,
          triggerSource: 'backoffice-manual-action'
        }
      })
    )
    yield put(createAlert('success', response.data, `Task orchestration - success`))
  } catch (err) {
    console.error(err)
    window.captureException(err)
    if (err.response.status === 409) {
      yield put(createAlert('warning', err.response.data, `Task orchestration - blocked`))
    } else {
      yield put(createAlert('danger', err.message, `Task orchestration - failed`))
    }
  }
}

function* updateEtlCheckpoint({ id, taskType, param, value }) {
  try {
    const response = yield call(() => axios.get('/api/admin/etl-state', { params: { id, param, type: taskType } }))
    if (response && response.data && response.data.length > 0) {
      yield call(() => axios.put(`/api/admin/etl-state/${response.data[0].id}`, { value }))
      return yield put(successModal(`Checkpoint successfully updated to ${value}!`))
    } else {
      return yield put(errorModal(`No checkpoint found for this ETL state.`))
    }
  } catch (err) {
    window.captureException(err)
    yield put(errorModal(`Error updating the checkpoint: ${err.message}`))
  }
}

function* loadETLStates(action = {}) {
  const state = yield select(state => state)
  const { sortBy, sortOrder, sizePerPage, pageNumber, search } = state.etlStates
  try {
    const response = yield call(() =>
      axios.get('/api/admin/etl-state', {
        params: {
          sort: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`,
          offset: (pageNumber - 1) * sizePerPage,
          count: sizePerPage,
          q: search,
          excludeType: 'idf-shipping'
        }
      })
    )
    const parts = contentRange.parse(response.headers['content-range'])
    yield put({
      type: ETL_STATE_LOAD_SUCCEEDED,
      data: response.data,
      count: parts.length,
      offset: action.offset
    })
  } catch (err) {
    window.captureException(err)
    yield put({ type: ETL_STATE_LOAD_FAILED, message: err.message })
  }
}

function* loadTasks(action = {}) {
  try {
    const { offset, count, searchField, searchValue } = action.payload || {}
    const response = yield call(() =>
      axios.get('/api/etl-tasks', {
        params: {
          sort: '-createdAt',
          offset,
          count,
          searchField,
          searchValue
        }
      })
    )
    const parts = contentRange.parse(response.headers['content-range'])
    yield put({
      type: TASK_LOAD_SUCCEEDED,
      data: response.data,
      count: parts.length,
      offset
    })
  } catch (err) {
    window.captureException(err)
    yield put({ type: TASK_LOAD_FAILED, message: err.message })
  }
}

function* taskRetry(action) {
  yield taskUpdate({ data: { ...action.data, status: 'retried' } })
  yield taskDoAgain({
    task: action.data
  })
}

function* taskDoAgain(action) {
  try {
    const response = yield axios.post('/api/etl-tasks/retry', action.task)
    yield put(createAlert('success', response.data.type, `Task orchestration - success`))
  } catch (err) {
    window.captureException(err)
    yield put({ type: TASK_LOAD_FAILED, message: err.message })
    if (err.response.status === 409) {
      yield put(createAlert('warning', err.response.data, `Task orchestration - blocked`))
    } else {
      yield put(createAlert('danger', err.message, `Task orchestration - failed`))
    }
  }
}

function* taskUpdate(action) {
  try {
    yield axios.put(`/api/etl-task/${action.data.id}`, action.data)
  } catch (err) {
    window.captureException(err)
    yield put({ type: TASK_LOAD_FAILED, message: err.message })
  }
}

function* taskDelete(action) {
  try {
    yield axios.delete(`/api/etl-task/${action.data.id}`)
  } catch (err) {
    window.captureException(err)
    yield put({ type: TASK_LOAD_FAILED, message: err.message })
  }
}

function* taskCreate(action) {
  try {
    yield axios.post('/api/admin/etl-tasks/', action.data)
  } catch (err) {
    window.captureException(err)
    yield put({ type: TASK_LOAD_FAILED, message: err.message })
  }
}

function* fetchETLStats(action = {}) {
  try {
    const etlStats = yield call(() =>
      axios.get('/api/etl-stats', {
        params: action.payload
      })
    )

    yield put(fetchETLStatsSuccess(etlStats.data))
  } catch (error) {
    window.captureException(error)
    yield put(fetchETLStatsFailed(error.message))
  }
}

function* fetchETLStatsTimeframe() {
  try {
    const etlTaskTimeframe = yield call(() => axios.get('/api/etl-stats-timeframe'))

    yield put(fetchETLStatsTimeframeSuccess(etlTaskTimeframe.data))
  } catch (error) {
    window.captureException(error)
    yield put(fetchETLStatsTimeframeFailed(error.message))
  }
}

export default [
  fork(taskLoadFlow),
  fork(taskUpdateFlow),
  fork(taskCreateFlow),
  fork(taskRetryFlow),
  fork(taskDeleteFlow),
  fork(orchestrateFlow),
  fork(updateEtlCheckpointFlow),
  fork(taskDoAgainFlow),
  fork(etlStateFlow)
]
